





























































































































































































































import { Vue, Component } from "vue-property-decorator";
import { settingsServices } from "@/services/settings.service";
import {
  ResponseListOfCoa,
  ResponseListOfTax,
  ResponseListOfTaxType,
} from "@/models/interface/settings.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { Messages } from "@/models/enums/messages.enum";
import { ResponsePagination } from "@/models/constant/interface/common.interface";
import {
  currencyFormat,
  changeCurrencytoNumeric,
  numericdotOnly,
  currencyFormatDot,
} from "@/validator/globalvalidator";
import { masterServices } from "@/services/master.service";
import { ColumnTableCustom } from "@/models/interface/util.interfase";
import { debounce } from "@/helpers/debounce";
@Component
export default class Tax extends Vue {
  formatCurrency = currencyFormat;
  formatCurrencytoNumber = changeCurrencytoNumeric;
  formatCurrencyDot = currencyFormatDot;
  direction = "" as string;
  search = "" as string;
  isFormSubmitted = false as boolean;
  dataListTax = {} as ResponseListOfTax;
  dataListCoa = {} as ResponseListOfCoa;
  form = this.$form.createForm(this, { name: "tax" });
  page = 0 as number;
  dataSetTaxType = ["VAT In", "VAT Out", "Income Tax"];
  limit = 10 as number;
  trigerdisable = false as boolean;
  titlemodalOpen = "lbl_tax" as string;
  dataListTaxType = [] as ResponseListOfTaxType[];
  modalOpen = false as boolean;
  sort = "" as string;
  authPrivilege = [] as string[];
  pageSizeSet = 10;
  typemodal = "" as string;
  loadingTable = false as boolean;
  columnsTable = [
    {
      title: this.$t("lbl_tax_code"),
      dataIndex: "code",
      key: "taxcode",
      sorter: true,
      width: 100,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$root.$t("lbl_description"),
      dataIndex: "description",
      key: "description",
      sorter: true,
      scopedSlots: { customRender: "isNull" },
      width: 100,
    },
    {
      title: this.$t("lbl_tax_rate"),
      dataIndex: "rate",
      key: "taxrate",
      sorter: true,
      width: 100,
      scopedSlots: { customRender: "isCurrency" },
    },
    {
      title: this.$t("lbl_tax_account"),
      dataIndex: "taxAccountDescription",
      key: "accountpayables",
      sorter: true,
      width: 200,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_tax_type"),
      dataIndex: "taxType",
      key: "taxType",
      sorter: true,
      width: 200,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$root.$t("lbl_action").toString(),
      dataIndex: "operation",
      key: "operation",
      scopedSlots: { customRender: "operation" },
      button: ["update"],
      width: 120,
      align: "center",
      fixed: "right",
    },
  ] as ColumnTableCustom[];
  labelCol = { span: 6 };
  wrapperCol = { span: 16 };
  formRules = {
    rate: {
      label: "lbl_tax_rate",
      name: "rate",
      placeholder: "lbl_tax_rate",
      decorator: [
        "rate",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
            {
              validator: numericdotOnly,
            },
          ],
        },
      ],
    },
    code: {
      label: "lbl_tax_code",
      name: "code",
      placeholder: "lbl_type_to_find_placeholder",
      decorator: [
        "code",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    tax: {
      label: "lbl_tax_account",
      name: "type",
      placeholder: "lbl_tax_account_placeholder",
      decorator: [
        "tax",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    taxType: {
      label: "lbl_tax_type",
      name: "taxType",
      placeholder: "lbl_tax_type_placeholder",
      decorator: [
        "taxType",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    description: {
      label: "lbl_description",
      name: "description",
      placeholder: "lbl_description_placeholder",
      decorator: [
        "description",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
  };
  mounted() {
    this.getListOfTax("");
    this.getListOfCoa("");
    this.getListOfMasterType();
  }
  getListOfMasterType(): void {
    let params = {
      name: "TAX_TYPE",
    } as RequestQueryParamsModel;
    masterServices.listMaster(params).then(res => {
      res.forEach((dataObject, index) => (dataObject.key = index));
      this.dataListTaxType = res;
    });
  }
  onBlur(value, form) {
    if (value.target.value && !this.form.getFieldError(`${form}`)) {
      let dataobj = {};
      dataobj[form] = this.formatCurrencyDot(value.target.value);
      this.form.setFieldsValue(dataobj);
    }
  }
  onFocus(value, form) {
    if (value.target.value && !this.form.getFieldError(`${form}`)) {
      let dataobj = {};
      dataobj[form] = this.formatCurrencytoNumber(value.target.value);
      this.form.setFieldsValue(dataobj);
    }
  }
  filterOption(input, option) {
    return (
      option.componentOptions.children[0].componentOptions.children[1].text
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    );
  }
  onSorterChange(response): void {
    if (response.sort.order) {
      this.direction = response.sort.order === "ascend" ? "asc" : "desc";
      this.sort = `${response.sort.field}:${this.direction}`;
    } else {
      this.ResetFilter(false);
    }
    this.getListOfTax("");
  }
  reponseEditTable(response): void {
    this.trigerdisable = false;
    this.typemodal = "edit";
    this.titlemodalOpen = "lbl_edit_tax";
    this.dataDetail = "/" + response.data.id;
    this.ResetFilter(false);
    this.getListOfTax("/" + response.data.id);
  }
  reponseDeleteTable(response): void {
    const path = "/" + response.data.id;
    settingsServices.deleteTax(path).then(() => {
      this.ResetFilter(false);
      this.getListOfTax("");
    });
  }
  ResetFilter(getlist): void {
    this.direction = "";
    this.search = "";
    if (getlist) this.getListOfTax("");
  }
  reponseSearchInput(response): void {
    let data = "";
    if (this.dataSetTaxType.includes(response)) {
      data = response.toUpperCase().replaceAll(" ", "_");
    } else {
      data = response;
    }
    if (response)
      this.search = `code~*${data}*_OR_description~*${data}*_OR_taxAccount.description~*${data}*_OR_taxAccount.code~*${data}*_OR_taxType~${data}`;
    else this.search = "";
    this.getListOfTax("");
  }
  responsePageSizeChange(response: ResponsePagination): void {
    this.limit = response.size;
    this.page = 0;
    this.getListOfTax("");
  }
  responseCurrentPageChange(response: ResponsePagination): void {
    this.page = response.page - 1;
    this.getListOfTax("");
  }
  createNew(): void {
    this.modalOpen = true;
    this.typemodal = "create";
    this.form.resetFields();
    this.titlemodalOpen = "lbl_create_new_tax";
    this.ResetFilter(false);
  }
  handleSearchCoa(value) {
    let paramsListOfCoa = {
      page: 0,
      limit: 10,
      search: `isParent~false`,
    } as RequestQueryParamsModel;
    if (value)
      paramsListOfCoa.search =
        `code~*${value}*_OR_description~*${value}*_AND_` +
        paramsListOfCoa.search;
    settingsServices.listOfCoa(paramsListOfCoa, "").then(data => {
      this.dataListCoa = data;
    });
  }
  getListOfCoa(path: string): void {
    let params;
    if (!path) {
      params = {
        limit: this.limit,
        page: this.page,
      } as RequestQueryParamsModel;
      if (this.search) params.search = this.search;
      if (this.direction) params.sorts = this.sort;
    }
    this.loadingTable = true;
    settingsServices
      .listOfCoa(params, path)
      .then(res => {
        if (!path) {
          res.data.map((dataObject, index) => (dataObject.key = index));
          this.dataListCoa = res;
        } else {
          let tempData = {
            data: [res],
          };
          this.dataListCoa = tempData;
        }
      })
      .finally(() => (this.loadingTable = false));
  }
  getListOfTax(path: string): void {
    let params;
    if (!path) {
      params = {
        limit: this.limit,
        page: this.page,
        sorts: "createdDate:desc",
      } as RequestQueryParamsModel;
      if (this.search) params.search = this.search;
      if (this.direction) params.sorts = this.sort;
    }
    this.loadingTable = true;
    settingsServices
      .listOfTax(params, path)
      .then(res => {
        if (!path) {
          res.data.forEach((dataObject, index) => (dataObject.key = index));
          this.dataListTax = res;
        } else {
          this.getListOfCoa(`/${res.taxAccountId}`);
          debounce(() => {
            this.form.setFieldsValue({
              rate: res.rate ? this.formatCurrency(res.rate) : "0.00",
              description: res.description ? res.description : "-",
              tax: res.taxAccountId ? res.taxAccountId : "-",
              code: res.code,
              taxType: res.taxType,
            });
          }, 200);
          this.modalOpen = true;
        }
      })
      .finally(() => (this.loadingTable = false));
  }
  submitForm(e: Event, type: string): void {
    e.preventDefault();
    this.form.validateFields((err, values) => {
      if (!err) {
        const datapost = {
          rate: this.formatCurrencytoNumber(values.rate),
          description: values.description,
          taxAccountId: values.tax ? values.tax : null,
          code: values.code,
          taxType: values.taxType,
        };
        switch (type) {
          case "create":
            settingsServices
              .createTax(datapost)
              .then(res => {
                if (res) {
                  this.modalOpen = false;
                  this.getListOfTax("");
                  this.form.resetFields();
                  this.$notification.success({
                    description: Messages.CREATE_SUCCESS,
                    message: "Success",
                    duration: 30,
                  });
                }
              })
              .catch(error => {
                this.isFormSubmitted = false;
                this.$notification.error({
                  description: error.details,
                  message: "Error",
                  duration: 30,
                });

                this.$notification.error({
                  description: Messages.CREATE_FAIL,
                  message: "Error",
                  duration: 30,
                });
              })

              .finally(() => (this.isFormSubmitted = false));
            break;
          case "edit":
            settingsServices
              .updateTax(datapost, this.dataDetail)
              .then(res => {
                if (res) {
                  this.modalOpen = false;
                  this.getListOfTax("");
                  this.form.resetFields();
                  this.$notification.success({
                    description: Messages.UPDATE_SUCCESS,
                    message: "Success",
                    duration: 30,
                  });
                }
              })
              .catch(error => {
                this.isFormSubmitted = false;
                this.$notification.error({
                  description: error.details,
                  message: "Error",
                  duration: 30,
                });

                this.$notification.error({
                  description: Messages.UPDATE_FAIL,
                  message: "Error",
                  duration: 30,
                });
              })

              .finally(() => (this.isFormSubmitted = false));
            break;
          default:
            break;
        }
      } else {
        this.$notification["error"]({
          message: "Error",
          description: "Form is mandatory",
        });
      }
    });
  }
  handleCancel(): void {
    this.modalOpen = false;
  }
}
